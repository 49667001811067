import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../signup.css";
import styled from "styled-components";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BodySignup = styled.div`
  padding: 0px;
  margin: 0px;
  font-family: var(--f-primary);
  overscroll-behavior: none;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#3c2f61')};
    border-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#3c2f61')};
    color: ${({ disabled }) => (disabled ? '#d1d1d1' : '#fff')};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#5a497a')};
      border-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#5a497a')};
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox + span {
    font-weight: normal; // Ensure the text is not bold
  }
`;

const ManagerSignup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showActivationMessage, setShowActivationMessage] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const onFinish = async (values) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/companies/signup`,
        {
          email: values.email,
          password: values.password,
          name: values.name,
          description: values.description,
          physical_address: values.address
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        console.log("Manager created successfully.");
        setShowActivationMessage(true);
        message.success("Account created successfully. Check your email to activate your account.");
        navigate("/login");
      } else {
        console.error("Error creating manager:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating manager:", error.message);
    }
  };

  return (
    <BodySignup>
      <div>
        <div className="getQuote_getQuoteForm__1DMlx">
          <div className="container">
            {showActivationMessage && (
              <div style={{ marginBottom: "16px", color: "green" }}>
                Please check your email to activate your account.
              </div>
            )}

            <Form form={form} onFinish={onFinish} layout="vertical">
              <div>
                <p style={{ fontSize: "20px" }}>Fill the form below to Sign up</p>
              </div>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Company Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Physical Address"
                name="address"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: false }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <StyledButton
                  className="btn btn-sm mb-0 w-100 signupbtn"
                  type="primary"
                  htmlType="submit"
                  disabled={!termsAccepted}
                >
                  Sign up
                </StyledButton>
              </Form.Item>
              <Form.Item>
                <StyledCheckbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                >
                  I agree to the <a href="https://samawati.org/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Terms of Use</a> and <a href="https://samawati.org/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Privacy Policy</a>.
                </StyledCheckbox>
              </Form.Item>
              <Link type="link" to="/login" style={{ color: "blue" }}>
                Already have an account? Log in
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </BodySignup>
  );
};

export default ManagerSignup;
