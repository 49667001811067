import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import LineChart from "./LineChart";
import axios from "axios";
import "../dashboard.css";
import styled from "styled-components";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DashboardStyle = styled.body`
  margin-left: 280px;
  overflow-x: hidden;
`;


const Dashboard = () => {
  const { token } = useAuth();
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [statsResponse, setStatsResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(30);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.error("No token available. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        const statsResponse = await axios.get(
          `${BASE_URL}/companies/members/stats?days=${selectedPeriod}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (statsResponse.status === 200 && statsResponse.data.status === "success") {
          setStatsResponse(statsResponse);

          const monthlySummaryResponse = await axios.get(
            `${BASE_URL}/companies/members/monthly_summary`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const rawData = monthlySummaryResponse.data.data;

          const formattedData = Object.entries(rawData).map(([month, count]) => ({
            month,
            count,
          }));

          setMonthlySummary(formattedData);
          setLoading(false);
        } else {
          throw new Error("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token, selectedPeriod]);
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <DashboardStyle>
      <div>
        <div
          className="content-container"
          style={{ marginLeft: "30px", padding: "20px" }}
        >
          <h4 style={{ marginLeft: "20px" }}>
            Summary{" "}
            <span
              style={{ fontSize: "15px", marginLeft: "20px", color: "#3c2f61" }}
            >
              last {selectedPeriod} days
              <select
            value={selectedPeriod}
            onChange={handlePeriodChange}
            style={{ marginLeft: "20px" }}
          >
            <option value={30}>30</option>
            <option value={60}>60</option>
            <option value={90}>90</option>
          </select>
            </span>
          </h4>
          
          <main className="main-content position-relative border-radius-lg card">
            <div
              className="container-fluid py-4"
              style={{ fontWeight: "bold" }}
            >
              {loading ? (
                <div className="text-center spinner">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-xl-3 col-sm-6">
                      <div>
                        <b>Invited</b>
                        <div className="signno">
                          {statsResponse.data.total_invited}
                        </div>
                      </div>
                      <div>
                        <b>Sign-ups</b>
                        <div className="signno">
                          {statsResponse.data.total_invited_used}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div>
                        <b>Activity type recorded</b>
                      </div>
                      {statsResponse.data.categories_summary &&
                        statsResponse.data.categories_summary.map(
                          (category, index) => (
                            <div className="progress-container" key={index}>
                              <div className="progress-label">
                                {category.name}
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    height: "10px",
                                    width: `${category.count}%`,
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    <div
                      className="col-xl-4 col-sm-6 mb-xl-0 mb-4"
                      style={{ marginLeft: "8%" }}
                    >
                      <div>
                        <b>Activities Recorded</b>
                        <div className="signno">
                          {statsResponse.data.total_activities}
                        </div>
                      </div>
                      <br />
                      <div>
                        <b>
                          Employee Activity Review (
                          {statsResponse.data.total_ratings} Reviews)
                        </b>
                      </div>
                      <div>
                        {statsResponse &&
                        statsResponse.data &&
                        statsResponse.data.average_rating ? (
                          <>
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                className={`fa fa-star ${
                                  index <
                                  Math.floor(statsResponse.data.average_rating)
                                    ? "checked"
                                    : "unchecked"
                                }`}
                              ></span>
                            ))}
                          </>
                        ) : (
                          <div>No rating available</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-5">
                      <div className="containerr">
                        <br />
                        <br />
                        <br />
                        <div className="word">Active Participants</div>
                        <div className="number">
                          {statsResponse.data.total_active_users}
                        </div>
                        <div className="word">Overall Participation Rate</div>
                       
                        <div className="percentage">
                          {statsResponse.data.total_users !== 0
                            ? `${Math.round(
                                (statsResponse.data.total_active_users /
                                  statsResponse.data.total_invited_used) *
                                  100
                              )}%`
                            : "0%"}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      style={{ marginRight: "8.1%" }}
                    >
                      <LineChart data={monthlySummary} />
                    </div>
                  </div>
                 
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </DashboardStyle>
  );
};

export default Dashboard;
