import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "../signup.css";
import styled from "styled-components";

const BodySignup = styled.body`
  padding: 0px;
  margin: 0px;
  font-family: var(--f-primary);
  overscroll-behavior: none;
`;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Login = () => {
  const [form] = Form.useForm();
  const { login, setEmail} = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
  
      const response = await axios.post(
        `${BASE_URL}/users/login`,
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: true }
      );
  
      const {
        message: loginMessage,
        token,
        email,
        phone_number,
        first_name,
        last_name,
      } = response.data;
  
      const userData = {
        email,
        phone_number,
        first_name,
        last_name,
      };
  
      if (response.status === 200) {
        setEmail(values.email);
        login(token, userData);
        message.success(loginMessage);
        navigate("/");
      } else {
        message.error(loginMessage);
      }
    } catch (error) {
      console.error(
        "Error during login:",
        error.response?.data || error.message
      );
      message.error("There was a problem logging in. Check your email and password.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <BodySignup>
      <div>
        <div className="getQuote_getQuoteForm__1DMlx">
          <div className="container">
            <Form form={form} onFinish={onFinish} layout="vertical">
              <div>
                <p style={{ fontSize: "20px" }}>Management Desk</p>
              </div>
              <Form.Item
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="btn btn-sm mb-0 w-100 signupbtn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Log In
                </Button>
              </Form.Item>
              <Link type="link" to="/signup" style={{ color: "blue" }}>
                Don't have an account? Sign up
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </BodySignup>
  );
};


export default Login;
