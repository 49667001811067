import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { message, Select, Button } from "antd";
import ConfirmationModal from "./ConfirmationModal";
import { CSVLink } from "react-csv";
import { useRef } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DashboardStyle = styled.body`
  margin-left: 280px;
  overflow-x: hidden;
`;
const { Option } = Select;
const Participants = () => {
  const { token } = useAuth();
  const [participantsData, setParticipantsData] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [participantIdToRemove, setParticipantIdToRemove] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [timePeriod, setTimePeriod] = useState(30); // Default time period
  const [editingStatus, setEditingStatus] = useState(null); // Track editing state for status dropdown
  const [newStatus, setNewStatus] = useState(""); // Track new selected status
  const dropdownRef = useRef(null);

  const fetchParticipantsData = async () => {
    try {
      if (!token) {
        console.error("No token available. Please log in again.");
        setLoading(false);
        return;
      }

      const participantsResponse = await axios.get(
        `${BASE_URL}/companies/members/list?time_period=${timePeriod}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (participantsResponse.status === 200) {
        const formattedData = participantsResponse.data.data.map(
          (participant) => ({
            id: participant.id,
            employee_name: `${participant.first_name} ${participant.last_name}`,
            activity_count: participant.activity_count,
            provider_name: participant.provider_name,
            activity_status: participant.activity_status,
            invited_on: formatUserCreatedAt(participant.invited_on),
            joined_on: formatUserCreatedAt(participant.joined_on),
          })
        );
        setParticipantsData(formattedData);
        setFilteredParticipants(formattedData);
      } else {
        throw new Error("Data retrieval not successful");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching Participants data:", error);
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchParticipantsData();
  }, [timePeriod, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setEditingStatus(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const filtered = participantsData.filter((participant) =>
        participant.employee_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredParticipants(filtered);
    } else {
      setFilteredParticipants(participantsData);
    }
  }, [searchQuery, participantsData]);

  const formatUserCreatedAt = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const removeParticipant = async (id) => {
    setParticipantIdToRemove(id);
    setShowConfirmationModal(true);
  };

  const handleConfirmRemove = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/companies/members/${participantIdToRemove}/remove`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setParticipantsData((prevData) =>
          prevData.filter(
            (participant) => participant.id !== participantIdToRemove
          )
        );
        message.success("Employee deleted successfully");
      } else {
        throw new Error("Failed to remove participant");
      }
    } catch (error) {
      console.error("Error removing participant:", error);
    } finally {
      setShowConfirmationModal(false);
      setParticipantIdToRemove(null);
    }
  };

  const handleCancelRemove = () => {
    setShowConfirmationModal(false);
    setParticipantIdToRemove(null);
  };

  const handleStatusChange = async (participantId) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/companies/members/status/${participantId}?status=${newStatus}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success") {
        await fetchParticipantsData();
        message.success("Status updated successfully");
        setEditingStatus(null);
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const csvData = filteredParticipants.map((participant) => ({
    Participant: participant.employee_name,
    "Activities participated": participant.activity_count,
    Invited: participant.invited_on,
    Joined: participant.joined_on,
    Provider: participant.provider_name,
    Status: participant.activity_status,
  }));

  return (
    <DashboardStyle>
      <div className="content-container">
        <br /> <br />
        <div className="row">
          <div className="col d-flex align-items-center">
            <h4 className="me-3">Summary</h4>
            <div className="d-flex align-items-center">
              <select onChange={(e) => setTimePeriod(parseInt(e.target.value))}>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
              </select>
              <span
                className="ms-2"
                style={{ fontSize: "17px", color: "#3c2f61" }}
              >
                days
              </span>
            </div>
          </div>

          <div className="col">
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <br />
        {loading ? (
          <main className="main-content position-relative border-radius-lg card">
            <div
              className="container-fluid py-4"
              style={{ fontWeight: "bold" }}
            >
              <div className="text-center spinner">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <div>
            <main className="main-content border-radius-lg card container-fluid">
              <div className="table-responsive">
                <div>
                  <div className="card-body">
                    {filteredParticipants.length === 0 ? (
                      <p>No results found</p>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr style={{ color: "black" }}>
                            <th scope="col">Participant</th>
                            <th scope="col">Activities participated</th>
                            <th scope="col">Invited</th>
                            <th scope="col">Joined</th>
                            <th scope="col">Provider</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>{" "}
                            {/* Add an empty column for the pencil icon */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredParticipants.map((participant, index) => (
                            <tr key={index}>
                              <td>
                                <i
                                  className="fa fa-user"
                                  style={{ color: "#3c2f61", fontSize: "17px" }}
                                ></i>{" "}
                                {participant.employee_name}
                              </td>
                              <td className="text-center">
                                {participant.activity_count}
                              </td>
                              <td>{participant.invited_on}</td>
                              <td>{participant.joined_on}</td>
                              <td className="text-center">
                                {participant.provider_name || "None"}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  color:
                                    participant.activity_status === "Active"
                                      ? "green"
                                      : participant.activity_status === "Paused"
                                      ? "yellow"
                                      : "red"   
                                }}
                              >
                                {editingStatus === participant.id ? (
                                  <div ref={dropdownRef}>
                                    <Select
                                      value={newStatus}
                                      onChange={(value) => setNewStatus(value)}
                                      style={{ width: 120 }}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                    >
                                      <Option value="active">Active</Option>
                                      <Option value="paused">Paused</Option>
                                    </Select>
                                    <Button
                                      type="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleStatusChange(participant.id);
                                      }}
                                      size="small"
                                      style={{
                                        marginTop: 5,
                                        marginLeft: 4,
                                        backgroundColor: "#3c2f61",
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                ) : (
                                  <>
                                    {participant.activity_status}
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        color: "grey",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        marginTop: 5,
                                      }}
                                      onMouseOver={(e) => {
                                        e.target.style.color = "blue";
                                      }}
                                      onMouseOut={(e) => {
                                        e.target.style.color = "grey";
                                      }}
                                      onClick={() => {
                                        setEditingStatus(participant.id);
                                        setNewStatus(
                                          participant.activity_status
                                        );
                                      }}
                                    ></i>
                                  </>
                                )}
                              </td>
                              <td>
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    color: "grey",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                    marginTop: 5,
                                  }}
                                  onMouseOver={(e) => {
                                    e.target.style.color = "red";
                                  }}
                                  onMouseOut={(e) => {
                                    e.target.style.color = "grey";
                                  }}
                                  onClick={() =>
                                    removeParticipant(participant.id)
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-6">
                        <Link
                          className="btn btn-primary btn-sm"
                          to="/onboarding"
                          style={{
                            backgroundColor: "#3c2f61",
                            color: "white",
                          }}
                        >
                          Invite participant(s)
                        </Link>
                      </div>
                      <div className="col-6 text-end">
                        <CSVLink
                          data={csvData}
                          filename={"participants.csv"}
                          className="btn btn-primary btn-sm"
                          style={{
                            border: "2px solid #3c2f61",
                            color: "#3c2f61",
                            backgroundColor: "white",
                          }}
                        >
                          Download CSV file
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
        {/* Confirmation Modal */}
        {showConfirmationModal && (
          <ConfirmationModal
            message="Are you sure you want to delete this participant?"
            onConfirm={handleConfirmRemove}
            onCancel={handleCancelRemove}
          />
        )}
      </div>
    </DashboardStyle>
  );
};

export default Participants;
