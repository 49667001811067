import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const storedEmail = sessionStorage.getItem("email");

  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") === "true"
  );
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState(storedEmail || "");
  const [token, setToken] = useState(sessionStorage.getItem("token") || "");

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("token");
    }
  }, [isAuthenticated, email,token]);

  const login = (token, user) => {
    setUserData(user);
    setToken(token);
    setIsAuthenticated(true);
    setError(null);
    sessionStorage.setItem("isAuthenticated", "true");
    
  };

  const logout = async () => {
    try {
      setUserData(null);
      setIsAuthenticated(false);
      setToken("");
      setError(null);
      sessionStorage.clear();
    } catch (error) {
      console.error("Error during logout:", error);
      setError("An error occurred during logout. Please try again.");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userData,
        email,
        token,
        setEmail,
        login,
        logout,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
