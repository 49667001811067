import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Button, message } from "antd";
import styled from "styled-components";
import { useAuth } from "../contexts/AuthContext";
import ConfirmationModal from "./ConfirmationModal";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DashboardStyle = styled.div`
  margin-left: 280px;
  overflow-x: hidden;
`;

const CardHeader = styled.h4`
  font-weight: bold;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px 0;
`;

const InviteButton = styled(Button)`
  margin-left: 12px;
  border: 2px solid #3c2f61;
  color: #3c2f61;
  background-color: white;
`;

const HorizontalLine = styled.div`
  border-top: 1px solid #ccc;
  margin: 5px 0;
`;

const InviteEmployees = () => {
  const [form] = Form.useForm();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState("");

  const fetchPendingInvites = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/companies/members/pending-invites`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPendingInvites(response.data || []);
    } catch (error) {
      console.error("Error fetching pending invites:", error);
      message.error("Failed to fetch pending invites. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sendInvitation = async (values) => {
    try {
      setLoading(true);
      const invitationResponse = await axios.post(
        `${BASE_URL}/companies/invites/add`,
        { email: values.email },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (invitationResponse.status === 200) {
        if (
          invitationResponse.data &&
          invitationResponse.data.status === "success"
        ) {
          message.success("Invitation successfully sent.");
          fetchPendingInvites();
          form.resetFields(["email"]);
        } else if (
          invitationResponse.data &&
          invitationResponse.data.status === "failed" &&
          invitationResponse.data.message === "Invite already done"
        ) {
          message.warning(
            "Invite for this email has already been sent. Please use a different employee email."
          );
          form.resetFields(["email"]);
        } else {
          form.resetFields(["email"]);
          message.error("An error occurred during invitation. Please try again.");
        }
      } else {
        form.resetFields(["email"]);
        message.error("An error occurred during invitation. Please try again.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      form.resetFields(["email"]);
      message.error("Failed to send invitation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const resendInvite = async (email) => {
    try {
      setLoading(true);
      await axios.post(
        `${BASE_URL}/companies/invites/resend`,
        { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      message.success("Invitation resent successfully.");
      fetchPendingInvites();
    } catch (error) {
      console.error("Error resending invitation:", error);
      message.error("Failed to resend invitation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const deleteInvite = async () => {
    try {
      setLoading(true);
      await axios.delete(`${BASE_URL}/companies/invites/${inviteToDelete}/remove`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success("Invite deleted successfully.");
      setShowConfirmation(false);
      fetchPendingInvites();
    } catch (error) {
      console.error("Error deleting invite:", error);
      message.error("Failed to delete invite. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingInvites();
  }, []);

  return (
    <DashboardStyle>
      <div className="content-container" style={{ marginLeft: "30px", padding: "20px" }}>
        <br /> <br />
        <div className="card">
          <CardHeader>Invite Employees</CardHeader>
          <div className="card-body">
            <Form form={form} onFinish={sendInvitation} layout="vertical">
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name="email"
                    rules={[{ required: true, type: "email" }]}
                  >
                    <Input placeholder="Employee Email" />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ backgroundColor: "#3c2f61", color: "white" }}
                  >
                    Send Invite
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <CardHeader>Pending Invites</CardHeader>
          <div className="card-body">
            {pendingInvites.length > 0 ? (
              <ul style={{ paddingLeft: 0 }}>
                {pendingInvites.map((invite, index) => (
                  <React.Fragment key={invite.id}>
                    <ListItem>
                      <div>
                        <i
                          className="fa fa-user"
                          style={{ marginRight: "5px", color: "#3c2f61", fontSize: "22px" }}
                        ></i>
                        {invite.user_email}
                      </div>
                      <div>
                        <InviteButton
                          onClick={() => resendInvite(invite.user_email)}
                        >
                          Resend Invite
                        </InviteButton>
                        <InviteButton
                          onClick={() => {
                            setInviteToDelete(invite.user_email);
                            setShowConfirmation(true);
                          }}
                        >
                          Cancel Invite
                        </InviteButton>
                      </div>
                    </ListItem>
                    {index < pendingInvites.length - 1 && <HorizontalLine />}
                  </React.Fragment>
                ))}
              </ul>
            ) : (
              <h6>No pending invites</h6>
            )}
          </div>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to cancel this invite?"
          onConfirm={deleteInvite}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </DashboardStyle>
  );
};

export default InviteEmployees;
